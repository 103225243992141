import type { Directive } from 'vue'

export const vClickOutside = {
  beforeMount: function (el: HTMLElement, binding: any, vNode: any) {
    if (typeof binding.value !== 'function') {
      // TODO: check this directive binding instance
      const compName = (binding.instance?.name as string) ?? 'undefined'
      let warn = `[Vue-click-outside:] provided expression '${binding.expression}' is not a function, but has to be`
      if (compName) warn += `Found in component '${compName}'`

      console.warn(warn)
    }
    const bubble = binding.modifiers.bubble
    const handler = (e: Event) => {
      // @ts-expect-error trust
      if (bubble || (!el.contains(e.target) && el !== e.target))
        binding.value(e, vNode.key)
    }
    // @ts-expect-error trust
    el.__vueClickOutside__ = handler
    // @ts-expect-error trust
    document.addEventListener('click', el.__vueClickOutside__)
    // @ts-expect-error trust
    document.addEventListener('touchstart', el.__vueClickOutside__)
  },
  unmounted(el: HTMLElement) {
    // @ts-expect-error trust
    document.removeEventListener('click', el.__vueClickOutside__)
    // @ts-expect-error trust
    document.removeEventListener('touchstart', el.__vueClickOutside__)
    // @ts-expect-error trust
    el.__vueClickOutside__ = null
  },
} satisfies Directive
